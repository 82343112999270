import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UwY6bMBC95yuslSrtHhwBy6YqUU+V+gs9VFVl8ADuGg+yB0K22n+vbCAhJF11e0vmefzmvXlm+zPunoddMzyy3xvGWnSKFJqMlWoAud8wpowDyljkf+eieK4sdkbyAjXajPXC3nMuylIZ4Au4QSn0eOjBd75wZSQMq4apOp5+2G9eN9t5nvRf5pHKtVocM1ZqGHxBaFUZrggal7ECDIH15V+dI1UeeYGGwNASevdgT2Ewzuf/Ucb2F4V4XUjGwkFJqmeeU3dwpwZV1bTG4oA1yvDbeBLwHAfu1IsyVcZytBIszzGYUaIhD8FKXKjnwsHD6dRhIkij4Kv2x2bSeLvztVZIGUiSqB1YkraB47wgC1qQ6uF9OcEerBZH3goDy7wEVbWQeFj1ttj6ngmczgbVVkjVuYzFyThaI4aThkLo4j6Oor5mnD0m7RAasSOvNGMGDVwsebdKn8gd6o6COMI2Y8lEYsf7vSf/laaPgejNTdV8d72o3c1FpU+jcFspw3Mkwmb2Y8H5KXBevZ2rR+IBDka+8bCmUPDgSRrNxgf+UBMdoa9Vop1dWk6yDZZ8RSSw3xTVX2qlpQUz2r+8/EavhR6sg9FBP6tUFoopjXjgE/53bY6EpYtLRbhsKeCcpgtT54WfvI+iDyFSPdhS44Efz9pPLydi6UpEfnsVazUF6q4xvvO7FCTGIH2+K4V2cPfj6pup/Ho49GDIncN9q5WtPmvr3lHC6x+9TA+oJgYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mx6';
export var confirmModalContainer = '_1ukx6mxb';
export var confirmModalContent = '_1ukx6mxa';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx5';
export var modalContentWrapper = '_1ukx6mx4';
export var modalDescription = '_1ukx6mx8';
export var modalFooter = '_1ukx6mx9';
export var modalHeader = '_1ukx6mx7';
export var modalOverlay = '_1ukx6mx3';
export var widthVar = 'var(--_1ukx6mx0)';