import "../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VV23KbMBB991fsY/MgD3YwTuWP6Qi0gBohMZKonXby7x0JcACDA0/MaG9nz9ld9r8OcSmTVEbwbwdQMVMIRZyuKbxG9e2y+9zte5fDwIUCa5y+fIWk2jldUYh9EIDDmyNMikJRyFA5NKNUx4VqAJmW2lD4w8wPQlieC4UkJLOYacWZ+SDB5cU751o5YsVfnASE95sNPtI/lCiK0lE47E8THCwgmasqhXq/1xrF7L2pjWuM9YG1Fm2P21K9hiS1tsIJ7TlNrZaNQ5/HtIijy4QockwmusRDMnsdHtQ7rWsUIGXZe2F0ozgFZ5iyNTOoXDBpw9GQLsnEuFmM47GVvLFoiEWJmaOgtMIR7iTgfprcVi+bR+d70nsmoxGcc4DTt3A+ty1wYWvJPijkEsOD/xIuDGZtkUzLplLe8ruxTuQeinKo3Nd6AIR9IcJhZYfPT5fyLeB5qP+kSsFqCm8t7lk9vud6UP/n4lgF6msjqpmdvXYlk2jMbruNV8FdSSE+rbol6SgmWo5ZN0Ud2xJzfy+S5auUygYfO4onHWUB3TycLaotzMaapRvhO0UPFyXpeJ5e8umo8flRWwPhPmLRYMQmkFbe0roxSK6lcDi5VmRWJMN6S7v2jHOhCgpR/6fqbpphXDT2/v/aqsTnf4ihaU9JBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_14hl6blc';
export var authMessage = '_14hl6bl2';
export var authModalContent = '_14hl6bl0';
export var captchaWrapper = '_14hl6bl1';
export var forgetPasswordButton = '_14hl6bl6';
export var forgetPasswordButtonRow = '_14hl6bl3';
export var linkButton = '_14hl6bl5';
export var resendCountdown = '_14hl6bla';
export var resendCountdownInButton = '_14hl6blb';
export var resendWrapper = '_14hl6bl7';
export var sendMagicLinkButtonRow = '_14hl6bl4';
export var sentMessage = '_14hl6bl9';
export var sentRow = '_14hl6bl8';
export var userPlanButton = '_14hl6bld';