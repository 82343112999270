import "../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51VwW7bMAy95yt4GdCsU2GnSdYq6JcMxcBItK3NlgRZbpwN+/fBsl05cVIsOziOKOrx8ZGUH75vnmm9SpJvEj0yqqw/vnjX0Cv8XgAIUxrH4Q3dHWOYZUoTsyUKKkwpybGwv9wt/iweBqA0nJOqtiUeOWQltbsFhDeTypHwymjeATeV7nYOSvqCQ5okn7pljpZDurLtFHR1GTT4bm0McHCdpfudIUewxwBmUUqlcw5J5+od6jozruL93xI93bHHh41tv4DAUgwLuB+0cChVy6yxlhxDLQrjWEEqL/wSPgNLl8sJgwBw/VzwWsI9pFvbhnPmjVxWmgOHQklJekp//YEW66tavKebJrYd9AXYo/iZO9NoyS5Vuuh4vNcYoFJ6SJLD+qxEm0ArRM5dxzydhw1SV+hypTmwaNobJ8lx0EZTSL/xpdIUDZnRntXqF3FIxxw7U4aVKo8clC7IKb+LHTuxzJMMJbboSPuTFDif9PZ/9v/21v4PhXvqk4pN2VnGp1etjdInSW+MbYKNN1MWXy+z+NHUXmVHJoz2pD2H2qIgtid/IApssFS5ZspTVXMQpD25ObGzkpyoE+xtvXz3OQysN0myu6Kop9azmoTREt3xgqhPIZ0xXdbGuQCwpla9po5K9OqNzvVajXpFqSP087/Vy5nDTfKMsR/X56EBROPqTgNr1AjQT0C4HZp6mOMJRx4mMTC9aWQjBPYKWhTKH8OlN4e/4pue4PTfidqjpxdjSb9+4Ly/cCfEXRF2hytylZzqNq5nhblSgVlnj1sxnpzGS7en8cb1WSE24dPxF+O6u4glBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_59e4202';
export var searchInput = '_59e4205';
export var spacer = '_59e420b';
export var tagColorIcon = '_59e420d';
export var tagColorIconWrapper = '_59e420c';
export var tagEditIcon = '_59e420a';
export var tagSelectorItem = '_59e4209';
export var tagSelectorTagsScrollContainer = '_59e4208';
export var tagsEditorRoot = '_59e4201';
export var tagsEditorSelectedTags = '_59e4204';
export var tagsEditorTagsSelector = '_59e4206';
export var tagsEditorTagsSelectorHeader = '_59e4207';
export var tagsInlineEditor = '_59e4200';
export var tagsMenu = '_59e4203';