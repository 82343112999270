import "../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VWwXLbIBC9+yu4dKY9kJFUJ3Xxx3SwQBYJAgLIktvpv3dAkgM2Viynh/ai0QC77+3uY5eHH3nBHwv7moFfKwDkgeqKyw6BmhFCxXYFgKW9hW8blHOmDDNuq6uZpdAoXFIEhOw0VtvV79XD5DT3TgkziuMjAhWnvTPbY4XARvl/zNleQGZpYxAoqbBURy6KtIvn1lhWHWEphaXCIuBJwB21HR1oK0wIE3sEikz1oFgPcDupCdXQSoVArnpgJGcEHLD+DCGuKiYoHI+Ukkv9xdk4TOhiQ2CIcIwgL5zPgOvXe7m+m5AgnPxJ9cNnM/5EHNaeg4NGIN+m6PhwCNO0tEwKBErJ28bT6BixNQJ5ln3aJsUQ4DymYw2AZ82fPq6Mb/+RMjZDVaSw0LCfFIF8RPVLHWX72iKwzjK3xh1WPa4VxXDQg6KYj7+ZhpZSEKyPAa9ThOuTRO4JMAjgeyCrYl5FoxNOK3t7JiexwD5sPafVIwK4tTKihNP1v3J/fF3GTETp2QSFiGuTrMN4RxrcTwqLSO2uttGo0k+u0oFZ6c1OvLKJ1YKqB97I8sysL7NQpLKQDYuzd5sugk8lFIAdLl/2WraCwFQOgm2lWROrP5X94VqO9U+OrUm4t2t2XNCYsNac2ta5uIbUBumpllcnz5aINMDaX5XjgrEQNmV/ytSaiRcEYg3XsYadft6G1ULIW8cI+wcm7vMHJu5scC/p4JLNZUIPzPn1CZ0mM6lomP+Bp8Z7arDeMzH29aERBGeEPxO9IIJdObur/G7qnp9f7sDo9WKkzjWs+xqp/jsYM1GYm0OfeNgjpwgwizkrL+bK5myuWO/+RHRzVrT2KjorpUjxPXiL1lANDeW0tK6BCppoh2N/KlttnHcl2cUL7oBqJ2bv8p2G7w+mCHVx1/EPniJ68ET9ObDsF+LCinFOSQx/jOGjF2UyS4GYJt3EYlrf8d4bI91Ja2Vz8+vuD+rY0qH8DQAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomButton = '_12l52tqw';
export var bottomLeft = '_12l52tq6';
export var button = '_12l52tqv';
export var collectionEditContainer = '_12l52tql';
export var confirmButton = '_12l52tqm';
export var ellipsis = '_12l52tq0';
export var icon = '_12l52tqu';
export var includeAddButton = '_12l52tqa';
export var includeItem = '_12l52tqe';
export var includeItemContent = '_12l52tqd';
export var includeItemContentIs = '_12l52tqc';
export var includeItemTitle = '_12l52tqb';
export var includeListTitle = '_12l52tq8';
export var includeTitle = '_12l52tqf';
export var pageList = '_12l52tqo';
export var pagesBottom = '_12l52tq2';
export var pagesBottomLeft = '_12l52tq1';
export var pagesList = '_12l52tq5';
export var pagesTab = '_12l52tq4';
export var pagesTabContent = '_12l52tq3';
export var previewActive = '_12l52tqx';
export var previewCountTips = '_12l52tqq';
export var previewCountTipsHighlight = '_12l52tqp';
export var resultPages = '_12l52tqn';
export var rulesBottom = '_12l52tq7';
export var rulesContainer = '_12l52tqk';
export var rulesContainerLeft = '_12l52tqj';
export var rulesContainerLeftContent = '_12l52tqh';
export var rulesContainerLeftContentInclude = '_12l52tqg';
export var rulesContainerLeftTab = '_12l52tqi';
export var rulesContainerRight = '_12l52tq9';
export var rulesTitle = '_12l52tqy';
export var rulesTitleHighlight = '_12l52tqs';
export var selectedCountTips = '_12l52tqr';
export var tabButton = '_12l52tqt';