import "../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UwW7bMAy95yuIAgOSgwI7TbtOvexPBlqiEzWyZEhM4m7Ivw+Sk8Z2mw7baSdb1KP43iOl5Y9SrbqXVSzg1wxgS2azZQllURy2zzOAo9G87dfHtNYmthZfJdSWuhRIX6FNIMXGOwnK233j8o53LKL5SRIOGOZCYF0bRyLHK4y0SKjWR9NnBrLI5kApWqHabYLfOz1Jvm6INpgGw6tQ3vqweJ6dZsuLmDKLyRsSjNtSMDwCrDKAfSuhSPUs1Xz+Db0DxYdqr2xr05FOIbRm44RhaqIERY4ppPDLPrKpEzvH5FhCbFGRqIiPRNmfFrU2biOhfGw7KFdF24043meO7yj0BosNthLWk5T1UPfIN6aO3zn2hx7F5oo5ngfjocjG5OM0KR+wt8N5RyNR66TpacLvIfMbFF1lBclXk4wTdCDHUQLu2Y8zl61F4/5O3yD/KWdOp/nduL/RL/51CgEqHzQFEVCbfbyclGNXmyrfibhF7Y+X2IDrt/+ni+Wq7aCfTIAGO3F18Msnt/cDBzigu2Cv9kGxvI9AGCcOSKyZwtmH8wW6uxtXxCp6u+dzRWbfTG9tZb3aDV4xhVbNE3UQsC7abjGcgWL50Mu83XWLaifKYjHh6jzPpcXIwteCX1taZOINho1xYkDtNPvekDYIUQUiB+g0zEeupvvQZ0+equlzcenJaQi9P0PfDLj0c4T6ehv1MUHjPiX4eOu8W806zU6/AYWpVjN3BgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_1c2xj2s1';
export var hideInSmallScreen = '_1c2xj2s7';
export var hideInWideScreen = '_1c2xj2s6';
export var iconButton = '_1c2xj2s5';
export var menu = '_1c2xj2s8';
export var menuItem = '_1c2xj2s9';
export var root = '_1c2xj2s0';
export var topNav = '_1c2xj2s2';
export var topNavLink = '_1c2xj2s4';
export var topNavLinks = '_1c2xj2s3';