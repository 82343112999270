import "../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV227iMBB95yvmpVKRahQC3W3N15h4INMa22s7JWy1/76yHUIS6FZoX6Jk7Jk558wli7dfa0K/hM8ZgDWeAhnNwaESgT5wMwM4kgw1h2VRPMTPGmlfh8v3gTQ721albTezP7NFjlqmqJK8VeLEYaewjR5C0V4zCnjwHCrUAd3AaZWczAe6nTJH1nKoSUrUmy8BThHtheXwMkKyTkEjAHZ08Tg+N2eTJIdVjlsZ1Rz0Fcp0zQfhQp9gPUrwfJtqDscuiL5gP6Qmtt6oJtykdhAtm9QjOKF7V6WgWJQeisXKAwqPjDQzTRggXfI6igsj4IO4H8I9MpYPi/nA8cekR4Y4g7EcivimcBe6V5exF7eIGCsqCqfu9BaHaN+K6n3vTKMlB0UahWN7JyShDo+vhcT9U3a1wqEOUDw8dfDFbkcaWWLKKqOMYztSCuUc1sXD/Gs5Mske3XJw82c6OhMpy1zOq5LHeeikLP5R77fGB9qdWGV0QB04eCsqZFsMR4zNfknLlfCBVTUpmWt1SRDL1gUYeLzcMXbfAwGwQkrSew7FuYeTonysdcA2MOvoINwpSz6U+TUPoNGBefqNHEjX6CiN09Y4iY7FwjY+toi9mp11NuWbHJa2BW8UyTGELtA5+bh/xjf7gyvI4yErO8bT/k1sz0uKAypF1lNq2mNNAVlSkYM2edP0QoipECNc2X6Yj2Rf2xaW5bUoHbR7oVzIrIrRDtt+D61mz/PpaF82gg9UvZ9GU0FaJefUg32mKmXqNJ4o/DIsdd8Uz1n2vIdrR/o9Ze8jytFfY/jPuF+exqNjHhVWIZo1DvLg/83W5ahvsEl/lffQB6ga5+MoWkOT/yh2i+3zX3MwWJBxWv8CXMrHdg4IAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--jq4ies0)';
export var innerBackdrop = 'jq4ies6';
export var innerContainer = 'jq4ies5';
export var root = 'jq4ies1';
export var showMoreTag = 'jq4iesb';
export var tag = 'jq4ies7';
export var tagIndicator = 'jq4iesc';
export var tagInline = 'jq4ies9 jq4ies8';
export var tagInnerWrapper = 'jq4ies8';
export var tagLabel = 'jq4iesd';
export var tagListItem = 'jq4iesa jq4ies7';
export var tagRemove = 'jq4iese';
export var tagsContainer = 'jq4ies2';
export var tagsListContainer = 'jq4ies4 jq4ies2';
export var tagsScrollContainer = 'jq4ies3 jq4ies2';