import "../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW7Y7aMBD8f09hqarESZjmi5D4/vRNKpM4sD1jI9uBo9W9e2UnIXHIpdfrISSC483Mzux6yfdndqkUPTCNfoRBGFVKR+j3A0LBV3dByMgjQThJvj65JWeVIQiHYbs2igpdSXUg6ECNgpcFDlZRvkQ4WOXpEgWrPHGLeL1EwRIFj/a51weEwrSj6DCzDtNRrmcpNrFDTXNLkTYUm82IIo5GFBuPIQnmGPKNA41iyxCmjaJ8xJBkYxFrjyLO5yiyzGInWSPFfWcjgjS5t4QsuHsdwuDe7fT68P22k2O/k1uuODi+eCXq11PkbXWaUl0rNd/J8WZEsZmlyJrKrCNLEbdio7/1cjTSEc7pCB1oELlLGL2zmaORjmhexge6+fNF3LTzPYoxaOg7ddXrw6pr48QxnaE0e+KYbcSewW5v+vVRajAgBUGKcWrgxOxdeWKq4vJM0B7KkomnIe56iJvFHmwWjlDpVkteG4d6lexiOr3N4gNi5ZEWYC7Ebtp1BdwwRdCW12pxomqBcZdy+PjosWCpYAeCoIIJw1R7sSFUwIE2qffjINaIUc0wCCxrgyqpzlSVGoGoQEAj7vogPnJ6wdpQwwg60lqz0rMvdfZNh6taCBA7L34ztDvPPLvzzYzd3sj0B+bHxuVdDD8D57jYU7FjxGW5tIkv+0ffqEr0uVXJ/rEquX+4Er8syUxZVBMzeQz+w2Gvktmwks0/bTz8c/1rXQZKqVM6PSdK0NYogkBwEAxXnLnpZa9Y7xWIZ4ICD27r4HodAUG10Mw89Xb6QpuMO2un9iopDNbwixFUUF4sxjHoG4oevXS7PCmHncBg2EGTQU/+rLWB6oILKQwTZrh1YspAQTl2jxJ0gLLkzotaM4U146wwBAkp2FvjdWBG8Z4RLbc/WWFwBTYRO5ntza1UJVNY0RJqTdDahg6Ay/cAT2FMzf5PMW5Li+edkrUocSG5VF0paVXZzjkqOFB1afZcsaaiznswzO26drsKunbBub2zDvyuYx/3Y/oU3zgyI/0Ns24dUbstXaTBEqXhEqXu7Xvt1P7CIEr20iqdd6afIdeT3ubf7qBVuNYOp1baAh0l3Jx6Rva2EZxtV8TQC6lGg2FqyGXN24z3GngCDVvgDrFvsaHEIckgj94wgr5Uqf34k6qNHWU45HO/ORsNuH9g+wPIonL8+Q4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = '_1012frs7';
export var DefaultAvatarBottomItemWithAnimationStyle = '_1012frs8';
export var DefaultAvatarContainerStyle = '_1012frs4';
export var DefaultAvatarMiddleItemStyle = '_1012frs5';
export var DefaultAvatarMiddleItemWithAnimationStyle = '_1012frs6';
export var DefaultAvatarTopItemStyle = '_1012frs9';
export var avatarFallback = '_1012frsd';
export var avatarImage = '_1012frsc';
export var avatarRoot = '_1012frsa';
export var avatarWrapper = '_1012frsb';
export var blurVar = 'var(--_1012frs1)';
export var hoverWrapper = '_1012frse';
export var removeButton = '_1012frsf';
export var sizeVar = 'var(--_1012frs0)';